import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const OrganizationSchoolConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/organization-school",
    component: React.lazy(() => import("./organization-school")),
  },
];
