import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const DisasterTypesConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/disaster-types",
    component: React.lazy(() => import("./disaster-types")),
  },
];
