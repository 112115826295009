import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const BeneficiaryStatusTypesConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/beneficiary-status",
    component: React.lazy(() => import("./beneficiary-status")),
  },
];
