import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const usersConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ["/user/:id?", "/user/ngo/:id?"],
    component: React.lazy(() => import("./user")),
  },
  {
    path: "/user",
    component: () => {
      return <Redirect to="/user" />;
    },
  },
];

export const DeletedUserConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ["/deleted-users/:id?", "/deleted-users/ngo/:id?"],
    component: React.lazy(() => import("./deleted-users")),
  },
  {
    path: "/deleted-users",
    component: <Redirect to="/deleted-users" />,
  },
];

export const MarketingUsersConfig = [
  {
    path: "/marketing-users-list",
    component: React.lazy(() => import("./marketing-users-list")),
  },
];
