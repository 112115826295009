import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const ManualTransactionConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/manual_transaction",
    component: React.lazy(() => import("./manual-transaction-list")),
  },
];
