import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const TransferFundsListsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/transfer-funds-lists",
    component: React.lazy(() => import("./transfer-funds-lists")),
  },
];
