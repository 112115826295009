import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const BankTypeConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/bank-type",
    component: React.lazy(() => import("./bank-type")),
  },
];
