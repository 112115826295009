import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const SendNotificationConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/sendnotification",
    component: React.lazy(() => import("./send-notification")),
  },
];
