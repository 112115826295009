import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const RecurringDonationConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ["/recurring-donation-lists/:id?"],
    component: React.lazy(() => import("./recurring-donation")),
  },
  {
    path: "/recurring-donation-lists",
    component: () => {
      return <Redirect to="/recurring-donation-lists" />;
    },
  },
];
