import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const HumanRightsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/human-rights",
    component: React.lazy(() => import("./human-rights")),
  },
];
