import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const ManageCountryCurrencyConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ["/manage-country-currency/:id?"],
    component: React.lazy(() => import("./manage-country-currency")),
  },
  {
    path: "/manage-country-currency",
    component: () => {
      return <Redirect to="/manage-country-currency" />;
    },
  },
];
