import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const NTEECategoryConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/ntee-category",
    component: React.lazy(() => import("./ntee-category")),
  },
];
